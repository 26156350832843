.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  flex: none;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  overflow: hidden;
}

.actions {
  margin-left: auto;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.back {
  color: var(--text02);
}

.editorContainer {
  padding: 0 1rem;
  height: 100%;
  overflow-y: scroll;
}
