.root {
  box-sizing: content-box;
  top: 0;
  position: sticky;
  background: var(--ui01);
  min-height: 3.2rem;
}

.titleWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 8px 4px;
  box-sizing: border-box;

  .triggerContainer {
    display: grid;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;

    padding-left: 4px;
    box-sizing: border-box;

    &.interactive {
      &:hover {
        background: var(--gray06);
        cursor: pointer;
      }
      &.readonly {
        &:hover {
          background: unset;
          cursor: auto;
        }
      }
    }
  }
}

.input {
  background: var(--gray06);
  height: 100%;
  width: 100%;

  font-weight: 700;
  font-size: 1rem;

  input {
    padding: 0 0 0 4px;
  }

  .withoutOutline {
    outline: none;
    border: none;
  }
}

.subTitle {
  display: flex;
  align-items: center;
  color: var(--text03);
}

.divider {
  padding-left: 4px;
  color: var(--text01);
}
