@use '@work4all/assets/theme/typography.scss';

.disabled {
  opacity: 0.5;
}

.table {
  min-width: fit-content;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.rightAligned {
  text-align: right;
}

.thead,
.tfoot {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1rem;
  line-height: 2rem;
  color: var(--text03);
  white-space: nowrap;
  border: 1px solid var(--ui04);
  border-width: 1px 0;

  .th {
    border-top: 1px solid var(--ui03);
    border-bottom: 1px solid var(--ui03);
  }
}

.tbody {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--text01);
  position: relative;
  height: calc(100% - 44px);
  overflow-x: hidden;
}

.tfoot {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1rem;
  line-height: 2rem;
  color: var(--text01);

  .th,
  .td {
    border-top: 1px solid var(--ui03);
    border-bottom: 1px solid var(--ui03);
  }
}

.tr {
  display: flex;

  &:not(:hover, .selected) {
    .checkbox {
      opacity: 0;
    }
  }
}

.tr-border {
  border-top: 1px solid var(--ui04);
  border-bottom: 1px solid var(--ui04);
}

.page-break {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--ui03);
  width: fit-content;
}

.th,
.td {
  min-height: 3rem;
  padding: 0.5rem;
  position: relative;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.input {
  min-width: 0;
  position: absolute;
  inset: 0;
  border: 2px solid var(--ui04);
  border-radius: 0.25rem;
  outline: none;
  padding: 0.5rem;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  background: var(--ui02);
}
.addArticleRow {
  height: 2.5rem;
  padding-left: 2.75rem;
}

[role='rowgroup'] [role='row'] .checkbox {
  display: flex;
  opacity: 0;
}

[role='rowgroup'] [role='row']:hover .checkbox,
[role='rowgroup'] [role='row'] .checkbox.checked {
  opacity: 1;
}

.table-cell {
  padding: 1rem 0.125rem;
}

.tableWrapper {
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.outerWrap {
  height: 100%;
  display: flex;
  flex: 1;
  overflow: auto;
}

.positionMaskToggle {
  border-top: 1px solid var(--ui04);
  border-left: 1px solid var(--ui04);
  height: 100%;
}

.canDrop {
  border: 1px dashed var(--brand01);
  background-color: var(--ui08);
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.parent {
  color: var(--text03);
}

.icon {
  position: absolute;
  transform: translate(-7px, 3px);
  color: var(--text03);
  padding: 0;
}

.iconCollapsed {
  transform: rotate(-90deg);
}

.line {
  position: absolute;
  width: 18px;
  transform: translate(30px, calc(-50% - 6px));
  border-bottom: 2px dotted var(--text03);
  height: calc(100% - 1px);
}

.longLine {
  position: absolute;
  border-left: 2px dotted var(--text03);
  top: 0;
  transform: translate(50%, 0);
}

.longLineWrapper {
  position: absolute;
  transform: translate(25px, 30px);
  height: 100%;
}

.selectedRow {
  background-color: var(--ui08);
}
