@use '@work4all/assets/theme/breakpoints.scss';

.container {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  border: 1px solid var(--ui04);
  @media (min-width: breakpoints.$sm) {
    width: 320px;
  }
  position: relative;
}

.header {
  font-weight: 700;
  border-bottom: 1px solid var(--ui04);
}

.row {
  padding: 0.5rem;
}

.double {
  grid-column-start: 2;
  grid-column-end: 4;
}

.first {
  display: flex;
  justify-content: center;
}

.last {
  display: flex;
  justify-content: flex-end;
}

.list {
  padding-left: 2rem;
}

.gray {
  color: var(--text03);
}

.longLine {
  position: absolute;
  border-left: 2px dotted var(--text03);
  height: calc(100% - 4rem);
  transform: translate(50%, 2rem);
}

.line {
  height: calc(100% - 1px);
  width: 25%;
  transform: translate(50%, -50%);
  border-bottom: 2px dotted var(--text03);
}

.firstLine {
  height: calc(50% - 1px);
  transform: translate(50%, 0%);
}

.icon {
  position: absolute;
  transform: translate(-2rem, 0%);
  color: var(--text03);
  padding: 0;
}

.iconCollapsed {
  transform: rotate(-90deg);
}
