.wrapper {
  width: 100%;
}

.noLeftRadiuses {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.noRightRadiuses {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.noTopRadiuses {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.noBottomRadiuses {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
