.fileUploadInputWrap {
  position: relative;
  display: inline-block;
}

.fileUploadInput {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
  &[disabled] {
    cursor: default;
  }
}
