@import 'libs/assets/theme/spacing.scss';

$searchInputHeight: 56px;

.navigation {
  padding: $spaceXS;
  padding-top: 0;

  &--fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0;
    border-radius: 0;
  }

  &__search {
    padding: $spaceXS 0;
  }
  &__search-input {
    background-color: unset;
  }

  &__content {
    display: flex;
    justify-content: space-around;
    gap: $spaceXS;
  }

  &__item {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: $spaceXL;
    padding: $spaceXS $spaceS;
    border-radius: $spaceXS;
    padding: $spaceS;
    height: 2.5rem;
    width: 100%;
    text-transform: capitalize;
    &--active {
      color: var(--brand01);
      background: rgba(73, 156, 224, 0.1);
    }
  }
}
