@import 'libs/assets/theme/breakpoints.scss';

.signature {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    border-radius: 0.5rem;
    border: 1px solid var(--ui05);
  }

  canvas {
    border-radius: 0.5rem;
  }
}

.dialog {
  max-width: 550px;
}
