@use 'libs/assets/theme/spacing.scss';

.root {
  display: flex;
  padding: spacing.$spaceXXS;
}

.mobileToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1;
  padding: 1rem;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;

  .inner {
    display: flex;
    gap: 1rem;
    align-items: center;
    pointer-events: all;
  }
}

.mobileFilters {
  padding: 0.5rem;
  display: flex;
  align-items: center;

  .chips {
    flex: 1;
    overflow-x: auto;
  }
}

.spacer {
  flex: 1;
}

.resetSelectionFab {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 1;
}
