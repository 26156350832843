.textWrapper {
  padding: 0 1rem 0 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  height: 4rem;

  &.showFavorites {
    padding: 0 0.25rem 0 0.5rem;
  }
}

.list-item-button {
  height: 100%;
  max-width: 100%;
}

.list-item-content-wrapper {
  width: 100%;
  min-width: 0;
}

.nothing-found {
  text-align: center;
  font-style: italic;
}

.deselect-icon {
  color: var(--ui05);
  margin-left: 0.5rem;
  flex: none;
}

.list-item-button-active {
  background-color: var(--ui02);
}

.favActive {
  color: var(--brand02);
}
.chipListWrapper {
  padding: 0 0.5rem;
}
