.box {
  display: flex;
  width: 216px;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px var(--elevationColor3);
  border-radius: 8px;
  padding: 8px 16px;
  flex-direction: column;
}

.box > :not(:last-child):not(:only-child) {
  margin-bottom: 12px;
}

.contactsSectionWrapper > :not(:last-child):not(:only-child) {
  margin-bottom: 8px;
}

.items {
  display: block;
}

.item {
  width: 100%;
  padding: 8px 0px;
  justify-content: flex-start;
}
