.check-circle {
  color: var(--text03);
  margin-top: 1rem;
  margin-right: 0.5rem;
  font-size: 1.2rem;

  &.check-circle-success {
    color: var(--success);
  }

  &.check-circle-success {
    color: var(--success);
  }

  &.check-circle-edit {
    color: var(--alert);
  }

  &.disabled {
    color: var(--ui04);
  }
}
