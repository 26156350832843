@import 'libs/assets/theme/mixins.scss';

.root {
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  grid-gap: 0.25rem;

  .header {
    display: grid;
    grid-template-areas: 'title actions';
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content max-content;
    align-items: center;
    padding: 0 0.5rem 0 0;

    .title {
      grid-area: title;
    }

    .subtitle {
      grid-area: subtitle;
      color: var(--text03);
    }

    .actionContainer {
      grid-area: actions;
    }

    .closeButton {
      grid-area: close;
    }

    &.mobile {
      grid-template-areas: 'close title actions' 'close subtitle actions';
      grid-template-columns: max-content 1fr max-content;
      grid-gap: 0 1rem;

      padding: 1.25rem;
      box-shadow: 0px 2px 12px var(--elevationColor1);
      z-index: 1;
    }
  }

  &.mobile {
    grid-gap: 0;
  }

  .content {
    height: 100%;
    overflow: auto;
    padding: 0 0.5rem 0 0;
    @include mutedScroller;

    &.mobile {
      padding: 0 1rem 1rem;
      background: var(--uiInput);
    }
  }
}
