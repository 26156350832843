.treeItem {
  color: var(--text01);
  .content {
    padding: 8px;
  }

  .group {
    margin-left: 24px;
  }

  .iconContainer {
    padding: 8px 4px;
    margin: -8px 0;
    width: 32px;

    svg {
      font-size: 24px;
      color: var(--text03);
    }
  }

  .selected {
    .label {
      color: var(--brand01);
    }
  }
}

.drag-handler {
  display: flex;
  width: 40px;
  color: var(--text03);
  svg {
    width: 24px;
    height: 24px;
  }

  cursor: move;
}

.label {
  color: var(--text01);
}
