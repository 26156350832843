.root {
  display: flex;
  gap: 1rem;
}

.content {
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
