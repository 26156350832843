@import 'libs/assets/theme/mixins.scss';

.wrapper {
  padding: 0 1rem;
}

.secondaryItemLabel {
  color: var(--text03);
}

.treeWrapper {
  overflow: auto scroll;
  @include mutedScroller;
}
