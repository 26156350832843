@import 'libs/assets/theme/spacing.scss';

.container {
  position: relative;
}

.tooltip {
  z-index: 100;
  width: 100%;

  &[x-placement='top'] {
    top: 100% !important;
  }

  &[x-placement='bottom'] {
    top: -100% !important;
  }
}

.chipsContainer {
  display: flex;
  margin-top: $spaceS;
  min-height: $spaceM;
  flex-wrap: wrap;
  gap: $spaceXXS $spaceXS;
}

.add {
  padding: 0 $spaceXS;
  color: var(--brand01);
}
