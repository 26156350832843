@use '@work4all/assets/theme/breakpoints.scss';

.metaData {
  display: flex;
}

.left {
  width: 100%;
}

.right {
  width: 50rem;
}

.xSplit {
  display: flex;
  gap: 1rem;
  & > * {
    width: 100%;
  }
}

.sectionPadding {
  padding: 1rem;

  @media (min-width: breakpoints.$lg) {
    padding: 1rem 3.5rem;
  }
}
