@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/breakpoints.scss';

.popover-paper-root {
  height: Min(400px, calc(100% - 32px));
  width: Min(1000px, calc(100% - 32px));
  @include elevations.elevation6;
  border-radius: 0.5rem;
}

.picker {
  height: calc(100% - 2rem);
  margin: 1rem;
  @include elevations.elevation4;
  border-radius: 0.5rem;
  background-color: var(--ui01);
}

.resizable {
  background-color: var(--dayUIBackground);
}

.actionBar {
  padding: 0 1rem;
  flex: none;
}

.tableWrapper {
  overflow-y: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: breakpoints.$md) {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 6rem;
  }
}
