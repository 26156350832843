@import 'libs/assets/theme/spacing.scss';

.info-card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: -0.5rem;

  .info-card {
    display: grid;
    align-items: flex-start;
    grid-template-columns: auto 1fr;
    transition: background ease 0.2s;

    .info-card-decorator {
      width: max-content;
      align-self: center;
      justify-self: start;
    }

    .info-card-content {
      display: grid;
      justify-items: start;
      align-content: start;
      gap: $spaceXXS;
      overflow: hidden;

      .info-card-content-inner {
        display: flex;
        gap: 0.25rem;
        max-width: 100%;
        overflow: hidden;
      }
    }
  }
}

.interactive {
  &:hover {
    background: var(--ui02);
    cursor: pointer;
  }
}

.width-full {
  width: 100%;
}
