.disabled {
  opacity: 0.5;
}

.tableRow {
  height: 40px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.headerRow {
  color: var(--ui05);
}

.placeholderRow {
}

.checkboxWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.tableCell {
  padding-left: 2px;
  padding-right: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;

  .headerRow & {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.alignRight {
    text-align: right;
  }
}
