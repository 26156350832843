@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/mixins.scss';
@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

// Keep the list item height in sync with the normal picker.
$listItemHeight: 50px;

.root {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.search {
  padding: 0 1rem;
}

.row {
  @include typography.body1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  height: $listItemHeight;
}

.nothingFound {
  text-align: center;
  font-style: italic;
}

.muted {
  color: var(--ui05);
  font-weight: 700;
  border-top: 1px solid var(--ui04);
  border-bottom: 1px solid var(--ui04);

  & + & {
    border-top: none;
  }
}

.headerRow {
  position: sticky;
  top: 0;
  background: var(--ui01);
  z-index: 1;
}

.mark {
  flex: none;
  width: 1.5rem;
}

.number {
  flex: none;
  width: 1.75rem;
}

.text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amount {
  flex: none;
  width: 3.25rem;
  text-align: right;
}

.unit {
  flex: none;
  width: 3.25rem;
}
