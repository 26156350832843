.root {
  min-width: auto;
  min-height: auto;
  text-transform: unset;
  opacity: 1;
  padding: 0.5rem;
  color: var(--text02);
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  border-radius: 0.25rem;
}

.selected {
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: currentColor;
    opacity: 0.1;
    pointer-events: none;
  }
}

.error {
  color: var(--error) !important;
}

.badge {
  top: 0.25rem;
}

.tabWrapper {
  padding: 0.5rem 1rem 0.5rem 0;
}
