@use 'libs/assets/theme/variablesLight.scss';
@use 'libs/assets/theme/variablesDark.scss';
@import 'libs/assets/theme/spacing.scss';

html {
  --brand01: #{variablesLight.$brand01};
  --brand02: #{variablesLight.$brand02};
  --brand03: #{variablesLight.$brand03};
  --topBarBg: #{variablesLight.$topBarBg};

  --text01: #{variablesLight.$text01};
  --text02: #{variablesLight.$text02};
  --text03: #{variablesLight.$text03};
  --textInverse: #{variablesLight.$textInverse};

  --ui01: #{variablesLight.$ui01};
  --ui02: #{variablesLight.$ui02};
  --ui03: #{variablesLight.$ui03};
  --ui04: #{variablesLight.$ui04};
  --ui05: #{variablesLight.$ui05};
  --ui06: #{variablesLight.$ui06};
  --ui07: #{variablesLight.$ui07};
  --ui08: #{variablesLight.$ui08};
  --uiInput: #{variablesLight.$uiInput};

  --gray06: #{variablesLight.$gray06};
  --purple: #{variablesLight.$purple};
  --hoverPrimary: #{variablesLight.$hoverPrimary};
  --hoverRow: #{variablesLight.$hoverRow};
  --supportInfo: #{variablesLight.$supportInfo};
  --supportSuccess: #{variablesLight.$supportSuccess};
  --supportError: #{variablesLight.$supportError};
  --supportWarning: #{variablesLight.$supportWarning};
  --projectPrimaryLightColor: #{variablesLight.$projectPrimaryLightColor};
  --projectSuccess: #{variablesLight.$projectSuccess};
  --success: #{variablesLight.$success};
  --alert: #{variablesLight.$alert};
  --error: #{variablesLight.$error};
  --errorLight: #{variablesLight.$errorLight};
  --widgetBorder: #{variablesLight.$widgetBorder};

  --userShade1: #{variablesLight.$userShade1};
  --userShade2: #{variablesLight.$userShade2};
  --userShade3: #{variablesLight.$userShade3};
  --userShade4: #{variablesLight.$userShade4};
  --userShade5: #{variablesLight.$userShade5};
  --userShade6: #{variablesLight.$userShade6};
  --userShade7: #{variablesLight.$userShade7};
  --userShade8: #{variablesLight.$userShade8};
  --userShade9: #{variablesLight.$userShade9};

  --bgShade1: #{variablesLight.$bgShade1};
  --bgShade2: #{variablesLight.$bgShade2};
  --bgShade3: #{variablesLight.$bgShade3};
  --bgShade4: #{variablesLight.$bgShade4};
  --bgShade5: #{variablesLight.$bgShade5};
  --bgShade6: #{variablesLight.$bgShade6};

  --elevationColor1: #{variablesLight.$elevationColor1};
  --elevationColor2: #{variablesLight.$elevationColor2};
  --elevationColor3: #{variablesLight.$elevationColor3};
  --elevationColor4: #{variablesLight.$elevationColor4};
  --elevationColor5: #{variablesLight.$elevationColor5};
  --elevationColor6: #{variablesLight.$elevationColor6};
  --elevationColor7: #{variablesLight.$elevationColor7};
  --elevationColor8: #{variablesLight.$elevationColor8};

  --labeledInputHeight: #{$spaceXL};
  --projectSearchBarHeight: #{$spaceXL};
  --headerHeight: 4rem;
  --mobileNavigationHeight: #{$spaceXXL};
  --projectInfoHeight: 4rem;
  --metaCardsHeight: 4.5rem;
  --commandBarHeight: #{$spaceXL};
  --basicTableRowHeight: 2.25rem;
  --basicTableCellPadding: 0.5rem;
  --popoverFilterContentWidthRem: 21rem;

  --cardWidgetRowHeightRem: #{$spaceL};
  --cardWidgetColMinWidthRem: #{$spaceL};
  --cardWidgetHeaderHeight: 3rem;

  --borderRadius: 8px;
  --modalMarginThreshold: 2.5rem;
  --slideTransitionTimeout: 250;
}

html[data-theme='dark'] {
  --brand01: #{variablesDark.$brand01};
  --brand02: #{variablesDark.$brand02};
  --brand03: #{variablesDark.$brand03};

  --topBarBg: #{variablesDark.$topBarBg};

  --text01: #{variablesDark.$text01};
  --text02: #{variablesDark.$text02};
  --text03: #{variablesDark.$text03};
  --textInverse: #{variablesDark.$textInverse};

  --ui01: #{variablesDark.$ui01};
  --ui02: #{variablesDark.$ui02};
  --ui03: #{variablesDark.$ui03};
  --ui04: #{variablesDark.$ui04};
  --ui05: #{variablesDark.$ui05};
  --ui06: #{variablesDark.$ui06};
  --ui07: #{variablesDark.$ui07};
  --ui08: #{variablesDark.$ui08};
  --uiInput: #{variablesDark.$uiInput};

  --gray06: #{variablesDark.$gray06};
  --purple: #{variablesDark.$purple};
  --hoverPrimary: #{variablesDark.$hoverPrimary};
  --hoverRow: #{variablesDark.$hoverRow};
  --supportInfo: #{variablesDark.$supportInfo};
  --supportSuccess: #{variablesDark.$supportSuccess};
  --supportError: #{variablesDark.$supportError};
  --supportWarning: #{variablesDark.$supportWarning};
  --projectPrimaryLightColor: #{variablesDark.$projectPrimaryLightColor};
  --projectSuccess: #{variablesDark.$projectSuccess};
  --success: #{variablesDark.$success};
  --alert: #{variablesDark.$alert};
  --error: #{variablesDark.$error};
  --errorLight: #{variablesDark.$errorLight};
  --widgetBorder: #{variablesDark.$widgetBorder};

  --userShade1: #{variablesDark.$userShade1};
  --userShade2: #{variablesDark.$userShade2};
  --userShade3: #{variablesDark.$userShade3};
  --userShade4: #{variablesDark.$userShade4};
  --userShade5: #{variablesDark.$userShade5};
  --userShade6: #{variablesDark.$userShade6};
  --userShade7: #{variablesDark.$userShade7};
  --userShade8: #{variablesDark.$userShade8};
  --userShade9: #{variablesDark.$userShade9};

  --bgShade1: #{variablesDark.$bgShade1};
  --bgShade2: #{variablesDark.$bgShade2};
  --bgShade3: #{variablesDark.$bgShade3};
  --bgShade4: #{variablesDark.$bgShade4};
  --bgShade5: #{variablesDark.$bgShade5};
  --bgShade6: #{variablesDark.$bgShade6};

  --elevationColor1: #{variablesDark.$elevationColor1};
  --elevationColor2: #{variablesDark.$elevationColor2};
  --elevationColor3: #{variablesDark.$elevationColor3};
  --elevationColor4: #{variablesDark.$elevationColor4};
  --elevationColor5: #{variablesDark.$elevationColor5};
  --elevationColor6: #{variablesDark.$elevationColor6};
  --elevationColor7: #{variablesDark.$elevationColor7};
  --elevationColor8: #{variablesDark.$elevationColor8};

  --labeledInputHeight: #{$spaceXL};
  --projectSearchBarHeight: #{$spaceXL};
  --headerHeight: 4rem;
  --mobileNavigationHeight: #{$spaceXXL};
  --projectInfoHeight: 4rem;
  --metaCardsHeight: 4.5rem;
  --commandBarHeight: #{$spaceXL};
  --basicTableRowHeight: 2.25rem;
  --basicTableCellPadding: 0.5rem;
  --popoverFilterContentWidthRem: 21rem;

  --cardWidgetRowHeightRem: #{$spaceL};
  --cardWidgetColMinWidthRem: #{$spaceL};
  --cardWidgetHeaderHeight: 3rem;

  --borderRadius: 8px;
  --modalMarginThreshold: 2.5rem;
  --slideTransitionTimeout: 250;
}
