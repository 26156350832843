@use 'libs/assets/theme/variables.scss';

.input {
  width: 100%;
  min-height: 2.5rem;
  min-width: 0;
  background: var(--ui02);
  border: 1px solid var(--ui04);
  border-radius: 0.25rem;
  outline: none;
  resize: none;
  margin: -0.25rem 0 0 -0.5rem;

  &::after {
    border-bottom: none;
  }
  &::before {
    border-bottom: none !important;
  }

  &.focused {
    border-color: var(--brand01);
    background-color: var(--ui01);
  }
}

.innerInput {
  border-bottom: none;
  width: 100%;
  border: 0;
  background-color: transparent;
  padding: 0;
  color: var(--text01);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  display: block;
  line-height: 1.25rem;
  padding: 0.25rem 0.375rem;

  &[type='number'] {
    text-align: right;
  }

  &:focus,
  &:focus {
    outline: 0;
  }
}
