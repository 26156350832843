.comment {
  word-wrap: break-word;
}

.comment [data-mention='user'] {
  background: #d2d2d2;
  padding: 2px 8px 4px 8px;
  color: #464646;
  border-radius: 99px;
  font-family: monospace;
}
