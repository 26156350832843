.input {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  width: 100%;
  min-width: 0;
  text-align: center;
  padding: 0.25rem;
  border: none;
  background: transparent;
  border-radius: 0.25rem;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    border: none;
    background: var(--ui01);
  }
}
