@import 'libs/assets/theme/spacing.scss';

.wrap {
  width: 100%;
  max-width: 50rem;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: $spaceS;
  padding-top: var(--w4a-safe-area-top, 0px);
  padding-right: var(--w4a-safe-area-right, 0px);
  padding-left: var(--w4a-safe-area-left, 0px);
  gap: $spaceS;

  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.userWrap {
  color: var(--ui01);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.usermenu {
  margin-bottom: $spaceS;
}

.upper {
  color: var(--ui01);
  text-transform: uppercase;
}

.bar {
  background-color: rgba(0, 0, 0, 0.25);

  * {
    color: #fff;
  }
}

.inverse {
  color: var(--textInverse);
}

.applyButton {
  align-self: flex-end;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.backgroundShape {
  clip-path: ellipse(90% 50% at center top);

  @media (min-width: 600px) {
    clip-path: ellipse(60% 45% at center top);
  }

  @media (min-width: 1280px) {
    clip-path: ellipse(60% 35% at center top);
  }
}

.optionsWrap {
  padding: 0 1rem;
}

.timeFrameSwitch {
  padding: 0;
}
.title {
  padding-top: 0.5rem;
  color: var(--text03);
}
