.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--ui01);
  z-index: 101;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: none;
  &.active {
    display: flex;
  }
}

.element {
  width: 19rem;
  height: 12.5rem;
  border-radius: 8px;
  border: 3px dashedvar(--text03);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text03);
  margin: 1rem;

  svg {
    margin-bottom: 0.75rem;
  }

  &.accept {
    border-color: var(--brand01);
    color: var(--brand01);
  }
  &.cant {
    opacity: 0.5;
  }
}
