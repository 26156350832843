.container {
  padding: 0rem 1rem 1rem 1rem;
}

.checkbox {
  padding: 0;
  display: flex;
  align-items: start;
}

.info {
  color: var(--brand01);
}
