.relative-container {
  position: relative;
}

.resizable-container {
  height: 100%;
  width: 100%;
  position: absolute;
}

.resizable-container > div:nth-child(1) {
  border-bottom: 1px solid #d3d4d8;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.cell {
  border-right: 1px solid var(--ui04);
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-row {
  background-color: var(--ui08);
}

.cell-checkbox {
  height: 3rem;
  border-radius: 20px;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    &:not(.disabled) {
      background-color: rgba(25, 118, 210, 0.04);
      cursor: pointer;
    }
  }
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.disabled {
    path {
      fill: rgba(0, 0, 0, 0.26);
    }
  }
}

.column {
  color: var(--text01);
  font-size: 0.8rem;
  line-height: 1rem;
  white-space: nowrap;
}
.column-45 {
  width: 100%;
  height: 134px;
  left: 40px;
  bottom: -34px;
  transform: rotate(45deg);
  position: absolute;
  border-left: 1px solid var(--ui04);
  &:hover {
    cursor: pointer;
    .text-rotate {
      text-decoration: underline;
    }
  }
  &-selected {
    text-decoration: underline;
    font-weight: bold;
  }
  z-index: 10;
}

.text-rotate {
  position: absolute;
  transform: rotate(-90deg);
  width: 134px;
  height: 28px;

  left: -53px;
  bottom: 86px;

  display: flex;
  align-items: center;
}
