@import 'libs/assets/theme/spacing.scss';

.infoCardWrapper {
  gap: $spaceS;
  display: grid;
  grid-template-columns: repeat(var(--columns, 2), 1fr);
  padding: $spaceS;
}
.divider {
  padding: $spaceXS;
}
