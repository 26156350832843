@import 'libs/assets/theme/mixins.scss';

.preview-text-content {
  padding: 1rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  flex: 1;
}

.scrollable {
  @include mutedScroller;

  overflow: auto;
}

.title {
  margin-bottom: 1rem;
}
