.secondaryIcons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.5rem;
}

.selectedIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: grid;
  place-content: center;
  background: var(--brand01);
  & > svg {
    color: var(--ui01);
    font-size: 1rem;
  }

  @media (min-width: 1280px) {
    width: 28px;
    height: 28px;
  }
}

.drag-handler {
  display: flex;
  padding: 0 8px;
  align-items: center;
  color: var(--text03);
  svg {
    width: 24px;
    height: 24px;
  }

  cursor: move;
}
