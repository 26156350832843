@use '@work4all/assets/theme/mixins.scss';

.grid {
  display: grid;
  grid-template-rows: auto 1fr;

  overflow: auto;
}

.pickerDisabled {
  opacity: 0.5;
}

.toolbar {
  border-bottom: 1px solid var(--ui04);
  display: flex;

  .search {
    flex: 1;
    position: relative;

    .searchIcon {
      pointer-events: none;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translate(0, -50%);
      color: var(--text03);
    }

    .searchInput {
      width: 100%;
      height: 100%;
      outline: none;
      padding: 1rem 40px 1rem 50px;
      font-size: 1rem;
      line-height: 1.5rem;
      border: none;
      background-color: var(--ui01);
      color: var(--text01);
    }
  }

  .searchClear {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    color: var(--text03);
  }
}

.actions {
  flex: none;
  padding-right: 16px;
  height: 54px;
  display: flex;
  align-items: center;
}

.groups {
  overflow: auto;

  @include mixins.mutedScroller;
}

.tableContainer {
  overflow: auto;
  flex: 1;
}

.groupsContainer {
  overflow-y: auto;
  overflow-x: hidden;
}

.table {
  height: 100%;
  border-left: 1px solid var(--ui04);
}

.tableDisabled {
  cursor: not-allowed;
}

.treeGroup {
  margin-left: 24px;
}

.tableWrapper {
  display: flex;
  overflow: auto;
}
