@import 'libs/assets/theme/spacing.scss';
@import 'libs/assets/theme/mixins.scss';

.cell {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: unset;
  color: unset;
  text-decoration: unset;
  background-color: inherit;

  height: 100%;
  display: flex !important;
  border: 0;
  width: 100%;
  height: 100%;
  flex: none;

  &[data-sticky-last-left-td]::after {
    content: '';
    display: block;
    background: linear-gradient(to right, rgb(0, 0, 0, 0.1), transparent);
    height: 100%;
    width: 16px;
    position: absolute;
    top: 0;
    right: -16px;
    pointer-events: none;
    opacity: 0;
    transition: 300ms opacity ease;
  }
  &[data-sticky-first-right-td]::before {
    content: '';
    display: block;
    background: linear-gradient(to left, rgb(0, 0, 0, 0.1), transparent);
    height: 100%;
    width: 16px;
    position: absolute;
    top: 0;
    left: -16px;
    pointer-events: none;
    opacity: 0;
    transition: 300ms opacity ease;
  }
}

:global([data-sticky-scroll-left='scroll'])
  .cell[data-sticky-last-left-td]::after {
  opacity: 1;
}

:global([data-sticky-scroll-right='scroll'])
  .cell[data-sticky-first-right-td]::before {
  opacity: 1;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.content {
  width: 100%;
  min-width: 0;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  @include ellipsis;

  &--no-padding-left {
    padding-left: 0;
  }

  &--no-padding-right {
    padding-right: 0;
  }

  &.card {
    padding: 0 0.5rem;
  }
}

.noFlex {
  flex: unset !important;
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-$spaceXS);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.highlighted {
  animation: fadeRight 0.2s linear;
}
