@use './../../../../../variables.scss';
@use '@work4all/assets/theme/breakpoints.scss';

.panel {
  display: grid;
  grid-template-columns: 6fr 4fr;
  padding-right: var(--sectionHorizontalPadding);
  grid-template-areas:
    'general receipt'
    'vendor-and-currency receipt'
    'permit receipt'
    'link-pre-documents receipt'
    'empty receipt'
    'bookings bookings';

  @media (max-width: breakpoints.$md) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'general'
      'vendor-and-currency'
      'permit'
      'link-pre-documents'
      'empty'
      'receipt'
      'bookings';
    padding-right: 0;
  }
}
