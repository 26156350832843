@import 'libs/assets/theme/mixins.scss';

.root {
  border-bottom: 0.0625rem solid var(--ui04);
  overflow: hidden scroll;
  height: calc(3.2rem + 1px);

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: auto;
  }
}

.draggableHeader {
  padding-left: 40px;
}
