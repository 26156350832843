@use '@work4all/assets/theme/variables.scss';
@use '@work4all/assets/theme/typography.scss';

.wrapper {
  color: var(--text03);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logoFont {
  font-size: 2rem;
  color: var(--text03);
  font-weight: 700;
}

.iconWrapper {
  @include typography.heading4;
  background: var(--text03);
  color: var(--textInverse);
  width: 3rem;
  height: 3rem;
  border-radius: 10rem;
  font-size: 1.5rem;
  line-height: 3rem;
  text-align: center;
  display: inline;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  width: 3rem;
  height: 3rem;
}
