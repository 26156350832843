@use 'libs/assets/theme/variables.scss';

.no-preview {
  width: 100%;
  height: 100%;
  color: var(--text01);
  display: grid;
  text-align: center;
  grid-template-columns: minmax(auto, 24rem);
  place-content: center;
}
