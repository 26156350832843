@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/breakpoints.scss';

@import 'libs/assets/theme/spacing.scss';

.root {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  @include elevations.elevationMainHead;
  border-radius: 0;
  position: relative;
  z-index: 10000;
  background-color: var(--ui01);
}

.breadcrumbs {
  padding: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 0;
}

.breadcrumbsHeading {
  font-size: 1.375rem;
  color: var(--text02);
}

.breadcrumbsWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 $spaceS;
  align-items: center;
}

.verticalPadding {
  &--s {
    padding-top: $spaceXS;
    padding-bottom: $spaceXS;
  }

  &--xs {
    padding-top: $spaceXS;
    padding-bottom: $spaceXS;
  }

  &--xxs {
    padding-top: $spaceXXS;
    padding-bottom: $spaceXXS;
  }
}

.horizontalPadding {
  &--s {
    padding-left: $spaceS;
    padding-right: $spaceS;
  }

  &--xs {
    padding-left: $spaceXS;
    padding-left: $spaceXS;
  }

  &--xxs {
    padding-left: $spaceXXS;
    padding-right: $spaceXXS;
  }
}

.tabsWrapper {
  padding: 0 1rem;

  @media (min-width: breakpoints.$lg) {
    padding: 0 3.5rem;
    height: 4.75rem;
    display: flex;
    align-items: center;
  }
}
