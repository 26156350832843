@use '@work4all/assets/theme/typography.scss';

.disabled {
  opacity: 0.5;
}

.table {
  width: 100%;
  min-width: fit-content;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.rightAligned {
  text-align: right;
}

.thead,
.tfoot {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1rem;
  line-height: 2rem;
  color: var(--text03);
  white-space: nowrap;
  border: 1px solid var(--ui04);
  border-width: 1px 0;

  .th {
    border-top: 1px solid var(--ui03);
    border-bottom: 1px solid var(--ui03);
  }
}

.tbody {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 2rem;
  color: var(--text01);
  overflow-y: auto;
}

.tfoot {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1rem;
  line-height: 2rem;
  color: var(--text03);

  .th,
  .td {
    border-top: 1px solid var(--ui03);
    border-bottom: 1px solid var(--ui03);
  }
}

.tr {
  display: flex;

  &:not(:hover, .selected) {
    .checkbox {
      opacity: 0;
    }
  }
}

.tr-border {
  border-top: 1px solid var(--ui04);
  border-bottom: 1px solid var(--ui04);
}

.page-break {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--text03);
  width: fit-content;
}

.th,
.td {
  min-height: 3rem;
  padding: 0.5rem;
  position: relative;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.input {
  min-width: 0;
  position: absolute;
  inset: 0;
  border: 2px solid var(--ui04);
  border-radius: 0.25rem;
  outline: none;
  padding: 0.5rem;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  background: var(--ui02);
}
.addArticleRow {
  height: 2.5rem;
  padding-left: 2.75rem;
}

[role='rowgroup'] [role='row'] .checkbox {
  display: flex;
  opacity: 0;
}

[role='rowgroup'] [role='row']:hover .checkbox,
[role='rowgroup'] [role='row'] .checkbox.checked {
  opacity: 1;
}

.customCell {
  min-height: 2.5rem;
}

.table-cell {
  padding: 1rem 0.125rem;
}

.tableWrapper {
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.outerWrap {
  height: 100%;
  display: flex;
  flex: 1;
  overflow: auto;
}

.positionMaskToggle {
  border: 1px solid var(--ui04);
  border-right: 0;
}

.mobilePositionsTable {
  width: 100%;
  border-spacing: 0;

  td {
    padding: 0.75rem 0.5rem;
    vertical-align: top;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1rem;
    }
  }

  @include typography.body1;

  .subline {
    @include typography.caption;
    color: var(--text03);
    width: 100%;
    display: flex;

    .left {
      flex: 1;
      display: flex;
      gap: 2rem;
    }
    .right {
    }
  }
  .title td {
    font-weight: 700;
    border-top: 1px solid var(--ui04);
    border-bottom: 1px solid var(--ui04);
    color: var(--text03);
  }
}

.strikethrough {
  text-decoration: line-through;
  overflow-y: auto;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
}
