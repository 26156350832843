@use '@work4all/assets/theme/elevations.scss';

.wrapper {
  position: sticky;
  top: 0;
  z-index: 1;

  padding-right: var(--w4a-safe-area-right, 0px);
  padding-left: var(--w4a-safe-area-left, 0px);

  @media (min-width: 1280px) {
    top: var(--headerHeight);
  }
}

.root {
  display: grid;
  grid-template-areas: 'search' 'tabs';
  align-items: center;
  position: relative;
  padding: 1rem;
  padding-top: calc(1rem + var(--w4a-safe-area-top, 0px));
  height: calc(9rem + var(--w4a-safe-area-top, 0px));
  background: var(--ui01);
  @include elevations.elevationMainHead;
  z-index: 10;
  align-content: space-between;

  @media (min-width: 1280px) {
    padding: 0 1rem;
    grid-template-columns: minmax(auto, 1fr) minmax(auto, 700px) 1fr;
    grid-template-areas: 'tabs search .';
    height: 5rem;
    align-content: center;
  }
}

.noRightsWrapper {
  background: var(--brand02);
  width: 100%;
  color: var(--text01);
  padding: 0.5rem;
  text-align: center;

  .bold {
    font-weight: 700;
  }
}

.tabs {
  grid-area: tabs;
  min-width: max-content;
}

.search {
  grid-area: search;
  background: var(--gray06);
}
