@import 'libs/assets/theme/spacing.scss';

@mixin active-input-state {
  background-color: var(--ui01);
  color: var(--ui05);
}

@mixin input-drop-shadow {
  filter: drop-shadow(0 0.25rem 0.5rem var(--elevationColor1));
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin mainHeadDropshadow {
  box-shadow: 0 0.125rem 0.75rem var(--elevationColor1);
}

@mixin errorStyle {
  &.error {
    border-radius: $spaceXXS;
    border-left: $spaceXXS solid var(--error);
  }

  .errorMessage {
    position: absolute;
    left: $spaceXS;
    bottom: -$spaceS;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;
    color: var(--error);
    white-space: nowrap;
    width: 100%;
  }
}

@mixin mutedScroller {
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--ui03);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--ui04);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--ui05);
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
