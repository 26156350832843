@import 'libs/assets/theme/spacing.scss';

$brand01: #499ce0;
$topBarBg: $brand01;
$brand02: #eec764;
$brand03: #727279;
$text01: #232735;
$text02: #505565;
$text03: #8b90a0;
$textInverse: #ffffff;
$ui01: #ffffff;
$ui02: #fafafc;
$ui03: #f0f1f3;
$ui04: #d3d4d8;
$ui05: #a1a4b1;
$ui06: #232735;
$ui07: #000000;
$ui08: #e5f1fa;
$uiInput: #eeeeee;
$dayUIBackground: #eeeeee;
$gray06: #f2f2f2;
$purple: #bb36ae;
$hoverPrimary: #3771a0;
$hoverRow: #eeeef1;
$supportInfo: #000000;
$supportSuccess: #000000;
$supportError: #000000;
$supportWarning: #000000;
$projectPrimaryLightColor: #5fa8e4;
$projectSuccess: #67b443;
$success: #67b443;
$alert: #d9a931;
$error: #e14e2e;
$errorLight: #f9e5e1;
$widgetBorder: #e3e3e3;
$userShade1: #b8defe;
$userShade2: #429819;
$userShade3: #b7ec8e;
$userShade4: #eaae17;
$userShade5: #fadb8f;
$userShade6: #e14e2e;
$userShade7: #f8b1a1;
$userShade8: #ce49e3;
$userShade9: #f4c1fc;
$bgShade1: #dceffe;
$bgShade2: #dbf5c6;
$bgShade3: #fcedc7;
$bgShade4: #fbd8d0;
$bgShade5: #f9e0fd;
$bgShade6: #f0f1f3;
$labeledInputHeight: $spaceXL;
$projectSearchBarHeight: $spaceXL;
$headerHeight: 4rem;
$mobileNavigationHeight: $spaceXXL;
$projectInfoHeight: 4rem;
$metaCardsHeight: 4.5rem;
$commandBarHeight: $spaceXL;
$basicTableRowHeight: 2.25rem;
$basicTableCellPadding: 0.5rem;
$popoverFilterContentWidthRem: 21rem;
$cardWidgetRowHeightRem: $spaceL;
$cardWidgetColMinWidthRem: $spaceL;
$cardWidgetHeaderHeight: 3rem;
$borderRadius: 8px;
$modalMarginThreshold: 2.5rem;
$slideTransitionTimeout: 250;
$elevationColor1: rgba(0, 0, 0, 0.08);
$elevationColor2: rgba(0, 0, 0, 0.2);
$elevationColor3: rgba(0, 0, 0, 0.3);
$elevationColor4: rgba(0, 0, 0, 0.4);
$elevationColor5: rgba(0, 0, 0, 0.5);
$elevationColor6: rgba(0, 0, 0, 0.6);
$elevationColor7: rgba(0, 0, 0, 0.7);
$elevationColor8: rgba(0, 0, 0, 0.8);

:export {
  brand01: $brand01;
  brand02: $brand02;
  brand03: $brand03;
  text01: $text01;
  text02: $text02;
  text03: $text03;
  textInverse: $textInverse;
  ui01: $ui01;
  ui02: $ui02;
  ui03: $ui03;
  ui04: $ui04;
  ui05: $ui05;
  ui06: $ui06;
  ui07: $ui07;
  ui08: $ui08;
  gray06: $gray06;
  hoverPrimary: $hoverPrimary;
  hoverRow: $hoverRow;
  supportInfo: $supportInfo;
  supportSuccess: $supportSuccess;
  supportError: $supportError;
  supportWarning: $supportWarning;
  projectPrimaryLightColor: $projectPrimaryLightColor;
  projectSuccess: $projectSuccess;
  success: $success;
  alert: $alert;
  error: $error;
  widgetBorder: $widgetBorder;
  userShade1: $userShade1;
  userShade2: $userShade2;
  userShade3: $userShade3;
  userShade4: $userShade4;
  userShade5: $userShade5;
  userShade6: $userShade6;
  userShade7: $userShade7;
  userShade8: $userShade8;
  userShade9: $userShade9;
  labeledInputHeight: $labeledInputHeight;
  projectSearchBarHeight: $projectSearchBarHeight;
  headerHeight: $headerHeight;
  mobileNavigationHeight: $mobileNavigationHeight;
  projectInfoHeight: $projectInfoHeight;
  metaCardsHeight: $metaCardsHeight;
  commandBarHeight: $commandBarHeight;
  basicTableRowHeight: $basicTableRowHeight;
  basicTableCellPadding: $basicTableCellPadding;
  popoverFilterContentWidthRem: $popoverFilterContentWidthRem;
  cardWidgetRowHeightRem: $cardWidgetRowHeightRem;
  cardWidgetColMinWidthRem: $cardWidgetColMinWidthRem;
  cardWidgetHeaderHeight: $cardWidgetHeaderHeight;
  modalMarginThreshold: $modalMarginThreshold;
  slideTransitionTimeout: $slideTransitionTimeout;
  elevationColor1: $elevationColor1;
  elevationColor2: $elevationColor2;
  elevationColor3: $elevationColor3;
  elevationColor4: $elevationColor4;
  elevationColor5: $elevationColor5;
  elevationColor6: $elevationColor6;
  elevationColor7: $elevationColor7;
  elevationColor8: $elevationColor8;
}
