$spaceXXXL: 8rem;
$spaceXXL: 4rem;
$spaceXL: 3rem;
$spaceL: 2rem;
$spaceM: 1.5rem;
$spaceS: 1rem;
$spaceXS: 0.5rem;
$spaceXXS: 0.25rem;

:export {
  spaceXXXL: $spaceXXXL;
  spaceXXL: $spaceXXL;
  spaceXL: $spaceXL;
  spaceL: $spaceL;
  spaceM: $spaceM;
  spaceS: $spaceS;
  spaceXS: $spaceXS;
  spaceXXS: $spaceXXS;
}
