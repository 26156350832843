@use 'libs/assets/theme/variables.scss';

.border {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 1rem;
}

.menu-item {
  min-height: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.favActive {
  color: var(--brand02);
}

.description {
  max-width: 60vw;
  text-overflow: ellipsis;
  overflow: hidden;
}
