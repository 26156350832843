@use 'libs/assets/theme/mixins.scss';

.control {
  background-color: var(--ui01);
  height: 100%;
  overflow-y: auto;
  @include mixins.mutedScroller;
}

.wrapper {
  display: grid;
  grid-template-columns: minmax(210px, auto) 40px;
  height: 100%;
}

.children {
  width: 100%;
}
