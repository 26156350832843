.disabled {
  opacity: 0.5;
}

.tableRow {
  height: 40px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.headerRow {
  color: var(--ui05);
}

.placeholderRow {
}

.checkboxWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.tableCell {
  padding-left: 2px;
  padding-right: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;

  .headerRow & {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.alignRight {
    text-align: right;
  }
}

.pickerButton {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;

  border: none;
  outline: none;
  background: transparent;

  &:is(:hover, :focus):not(:disabled),
  .placeholderRow &:not(:disabled) {
    background: var(--ui02);
  }

  &:disabled {
    cursor: default;
    opacity: 1;
    color: inherit;
  }
}

.pickerButtonContent {
  text-align: left;
  flex: 1;
  min-width: 0;
  min-width: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pickerButtonDropdownIcon {
  flex: none;
  display: none;

  :is(
      .pickerButton:is(:hover, :focus):not(:disabled),
      [role='row']:hover .pickerButton:not(:disabled)
    )
    & {
    display: block;

    color: var(--text02);
  }
}

.textInput {
  height: 100%;
  width: 100%;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
  text-overflow: ellipsis;

  border: none;
  outline: none;
  background: transparent;

  cursor: text;

  &:disabled {
    opacity: d;
  }

  &:is(:hover, :focus):not(:disabled),
  [role='row']:hover &:not(:disabled) {
    background: var(--gray06);
  }
}

.numberInput {
  text-align: right;
}
