.main {
  flex: 1;
  height: 100%;
}

.root {
  flex: 1;
  min-height: calc(100vh - 147px);
  @media (max-width: 1279px) {
    min-height: calc(100vh - 238px);
  }
}
