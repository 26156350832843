.actions {
  padding: 0;
  opacity: 0;
}

.table-row {
  min-height: 40px;

  .drag-handler {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    color: var(--text03);
    background-color: var(--ui01);
    margin: -2px 0;
    svg {
      width: 24px;
      height: 24px;
    }

    &[draggable] {
      cursor: move;
    }
  }

  &:hover {
    .actions {
      padding: 0;
      opacity: 1;
    }
  }
}

.drag-empty {
  width: 40px;
}

.expand-empty {
  width: 40px;
}
