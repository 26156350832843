.editWrap {
  position: relative;

  .iconContainer {
    position: absolute;
    top: 0;
    right: 100%;
    cursor: pointer;
    display: none;
    padding: 0 1rem;

    &.active {
      display: block;
    }
  }

  &:not(.disabled):hover {
    .iconContainer {
      display: block;
    }
  }
}
