.table-cell {
  min-height: 40px;
  padding: 6px 8px;
  overflow: hidden;
  text-overflow: ellipsis;

  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  white-space: pre;
}

:global {
  .inside-row {
    min-height: 100%;
    white-space: pre-line;
  }
}
