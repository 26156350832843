@use '@work4all/assets/theme/mixins.scss';

.root {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: max-content;
  padding: 1rem;
  gap: 1rem;
  overflow: auto;
  @include mixins.mutedScroller;
}
