.documentView {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: stretch;

  @media (max-width: 960px) {
    display: block;
  }
}

.wrapperRight {
  display: flex;
  flex-direction: column;
}
