@import '@work4all/assets/theme/spacing.scss';

.toggleButton {
  background-color: var(--ui03);
  color: var(--text01);
  border-radius: $spaceXXS;
  text-align: center;
}

.active {
  background-color: var(--brand01);
  color: var(--textInverse);
}
