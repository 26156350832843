.treeItem {
  .content {
    padding: 8px;
  }

  .group {
    margin-left: 24px;
  }

  .iconContainer {
    padding: 8px 4px;
    margin: -8px 0;
    width: 32px;

    svg {
      font-size: 24px;
      color: var(--text03);
    }
    &--hidden {
      display: none;
    }
  }

  .label {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .selected {
    .label {
      color: var(--brand01);
    }
  }
}
